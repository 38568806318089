<template>
  <router-view></router-view>
</template>

<script>
import wayCodeUtils from "@/utils/wayCode";
export default {
  components: {
  },
  mounted() {
    console.log("进入return页面");
    const { payOrderNo } = this.$route.query
    const { returnPage } = wayCodeUtils.getPayWay()
    this.$router.push({
      name: returnPage,
      query: { payOrderNo: payOrderNo },
    });
  }

}
</script>
